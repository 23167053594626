import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants'
import { AccountService } from './account.service';

@Injectable()
export class OrderService {
    orderList:Array<any>;
    shipping:any;
    account;
    constructor(
        private http: HttpClient,
        private accountService: AccountService,
    ){ 
        this.account = this.accountService.getAccount;
    }

    list(searchParams = {}):Observable<any> {
        searchParams['FirmaGuid'] =`${Constants.guid}`;
        searchParams['PlatformId'] =`${Constants.platformId}`;
        searchParams['apiId'] =`${Constants.apiId}`;
        searchParams['Skip'] = 0;
        searchParams['Take'] = 100;
        searchParams['Email'] = this.account.email ?? this.account.telefon;
        console.log(searchParams)
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/siparis/list`, searchParams, httpOptions)
        .pipe(map(res => {
            if (res['result']['status']) {
                this.orderList = res['data'];
                return res
            }else {
                this.orderList = [];
                return res
            }
        }))
    }

    singleOrder(contact_id, id): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/siparis/detay/${contact_id}/${id}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                } else {
                    return res;
                }
            }))
    }

    getShippingDetail(orderId, test?): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        const url = !test ? `${Constants.circleApiUrl}/kargo/takip/durum/${orderId}` : `${Constants.circleApiUrl}/kargo/takip/durum/${orderId}`
        return this.http.get(url, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.shipping = res['data'][0];
                    return res['data'][0];
                } else {
                    return res;
                }
            }))
    }

    cancelOrder(items): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/siparis/iptal`, items, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }

    rebateOrder(rebateForm): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/siparis/iade`, rebateForm, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }
}