import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Cart2Service } from './cart2.service';
import { ToastrService } from 'ngx-toastr';

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping' | 'fee' | 'tax' | 'other';
}

interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private data: CartData = {
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private onAddingSubject$: Subject<Product> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();

    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private cartService: Cart2Service,
        private toastr:ToastrService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
            // this.calc();
        }

    }

    add(product: Product, quantity: number, options: { name: string; value: string }[] = []): Observable<CartItem> {

        if(quantity != 0){
            return this.cartService.addCart(product, quantity).pipe(map((x)  => {
                console.log(product)
                if(x.status){
                        this.onAddingSubject$.next(product);
        
                        let item = this.items.find(eachItem => {
                            if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
            
                                return false;
                            }
            
                            if (eachItem.options.length) {
            
                                for (const option of options) {
                                    if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                                        return false;
                                    }
                                }
                            }
            
                            return true;
                        });
            
                        if (item) {
                            item.quantity += quantity;
                        } else {
            
                            // item = { product, quantity, options };
            
                            // this.data.items.push(item);
                        }
                        
                        this.load();
                        this.calc();
                        return item;
                }
                else{
                    this.toastr.error(x.description)
                }
               
            }))
        }
        // timer only for demo
    }

    update(updates: { item: CartItem, quantity: number }[]): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;
                }
            });

            this.save();
            this.calc();
        }));
    }

    remove(item: CartItem): Observable<void> {
        // timer only for demo
        this.cartService.deleteRow(item.product['SepetGuid']).subscribe(res => {
            this.load()
        })
        return timer(1000).pipe(map(() => {
            this.data.items = this.data?.items.filter(eachItem => eachItem !== item);

            // this.save();
        }));
    }

    private calc(): void {
        let quantity = 0;
        let subtotal = 0;

        this.data?.items.forEach(item => {
            quantity += item.quantity;
            subtotal += item['product'].price * item.quantity;
        });
        const totals: CartTotal[] = [];

        totals.push({
            title: 'Kargo',
            price: 0,
            type: 'shipping'
        });
        // totals.push({
        //     title: 'Tax',
        //     price: 0,
        //     type: 'tax'
        // });

        const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }

    private save(): void {
        // localStorage.setItem('cartItems', JSON.stringify(this.data.items));
        this.data.items = null
        this.load();
        this.calc();
    }

    async load() {
        // const items = localStorage.getItem('cartItems');
        this.data.items = []
        this.cartService.getCart().subscribe(async x  => {
            
            x?.forEach(async element => {
                // this.data.items.push(element)
                let y: any
                y = {
                    options: [],
                    product: element,
                    quantity: element['Quantity']
                }
                this.data.items.push(y)
            });
            this.calc()
        })
        // if (items) {
        //     // this.data.items = JSON.parse(items);
        // }
    }
}
