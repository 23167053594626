<div class="account-menu">
    <form formGroupName="'name'" class="account-menu__form" *ngIf="!account">
        <div class="account-menu__form-title">Hesabınıza Giriş Yapın</div>
        <div class="form-group">
            <label for="header-signin-email" class="sr-only">Email adresi</label>
            <input id="header-signin-email" [ngModelOptions]="{standalone: true}" [(ngModel)]="mail" type="email" class="form-control form-control-sm" placeholder="Email ">
        </div>
        <div class="form-group">
            <label for="header-signin-password" class="sr-only">Parola</label>
            <div class="account-menu__form-forgot">
                <input id="header-signin-password" [ngModelOptions]="{standalone: true}" [(ngModel)]="pass" type="password" class="form-control form-control-sm" placeholder="Parola">
                <!-- <a href="" class="account-menu__form-forgot-link">Forgot?</a> -->
            </div>
        </div>
        <div class="form-group account-menu__form-button">
            <button type="submit" class="btn btn-primary btn-sm" (click)="signin(mail,pass)">Giriş yap</button> <br>
            <span>veya</span>
            <div class="text-center">
                <button type="button" (click)="loginWithGoogle()" style="background:#f6f6f6;"
                    class="btn mt-1 mt-md-1 mt-lg-1">
                    <div class="row">
                        <i class="fab fa-google mr-2 my-auto"></i> <span> Google &nbsp;</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="account-menu__form-link"><a routerLink="/account/login" (click)="closeMenu.emit()">Hesap oluştur</a></div>
    </form>
    <div class="account-menu__divider"></div>
    <a routerLink="/account/profile" class="account-menu__user" *ngIf="account" (click)="closeMenu.emit()">
        <div class="account-menu__user-avatar">
            <img src="assets/images/avatars/user.png" alt="">
        </div>
        <div class="account-menu__user-info">
            <div class="account-menu__user-name">{{account?.fullName}}</div>
            <div class="account-menu__user-email">{{account?.email}}</div>
        </div>
    </a>
    <div class="account-menu__divider"></div>
    <ul class="account-menu__links" *ngIf="account">
        <li><a routerLink="/account/profile" (click)="closeMenu.emit()">Profil</a></li>
        <li><a routerLink="/account/orders" (click)="closeMenu.emit()">Sipariş geçmişi </a></li>
        <li><a routerLink="/account/addresses" (click)="closeMenu.emit()">Adresler</a></li>
        <!-- <li><a routerLink="/account/password" (click)="closeMenu.emit()">Password</a></li> -->
    </ul>
    <div class="account-menu__divider" *ngIf="account"></div>
    <ul class="account-menu__links" *ngIf="account">
        <li><a routerLink="/account/login" (click)="closeMenu.emit();logout()">Çıkış yap</a></li>
    </ul>
</div>
