import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Constants } from '../constants';

@Injectable()
export class PaymentService {
    paymentError: string;
    paymentSuccess: boolean;
    couponCodes = [];
    constructor(
        private http: HttpClient
    ) { }

    saveOrder(order) {
        order.FirmaGuid = Constants.guid;
        order.PlatformId = Constants.platformId;
        order.ApiId = Constants.apiId;
        order.uuid = localStorage.getItem('uuid');

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }


        return this.http.post(`${Constants.b2cApiUrl}/siparis/save`, order, httpOptions)
            .pipe(map(res => {
                return res;
            }))


    }

    payiyzico3d(payForm): Observable<any> {
        payForm.Config['FirmaGuid'] = Constants.guid;
        payForm.Config['BasariliUrl'] = payForm.Config.BasariliUrl;
        payForm.Config['HataUrl'] = payForm.Config.HataUrl;

        let tempProducts = payForm.UrunBilgileri;
        let fa = payForm.FaturaAdresiBilgileri;
        let ta = payForm.TeslimatBilgileri;
        delete payForm.UrunBilgileri;
        delete payForm.FaturaAdresiBilgileri;
        delete payForm.TeslimatBilgileri;

        payForm['Urunler'] = tempProducts;
        payForm['FaturaAdresi'] = fa;
        payForm['TeslimatAdresi'] = ta;

        return this.http.post(`https://pay.glopark.com/iyzico/ApiPayment3DSecure`, payForm)
            .pipe(map(res => {
                return res;
            }))
    }

    getPaymentData() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.get(`${Constants.b2cApiUrl}/sanal-pos-ayarlar/list`, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }

    createPaymentForm(bilingId, shippingId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.get(`${Constants.b2cApiUrl}/odeme-form-olustur/${bilingId}/${shippingId}?uuid=${localStorage.getItem('uuid')}`, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }

    paytr3d(payForm): Observable<any> {
        payForm.Config['FirmaGuid'] = Constants.guid;
        payForm.Config['BasariliUrl'] = payForm.Config.BasariliUrl;
        payForm.Config['HataUrl'] = payForm.Config.HataUrl;

        let tempProducts = payForm.UrunBilgileri;
        let fa = payForm.FaturaAdresiBilgileri;
        let ta = payForm.TeslimatBilgileri;
        delete payForm.UrunBilgileri;
        delete payForm.FaturaAdresiBilgileri;
        delete payForm.TeslimatBilgileri;

        payForm['Urunler'] = tempProducts;
        payForm['FaturaAdresi'] = fa;
        payForm['TeslimatAdresi'] = ta;

        return this.http.post(`https://pay.glopark.com/paytr/ApiPayment3DSecure`, payForm)
            .pipe(map(res => {
                return res;
            }))
    }


    payu3d(payForm): Observable<any> {
        payForm.Config['FirmaGuid'] = Constants.guid;
        payForm.Config['BasariliUrl'] = Constants.successUrl;
        payForm.Config['HataUrl'] = Constants.errorUrl;

        let tempProducts = payForm.UrunBilgileri;
        let fa = payForm.FaturaAdresiBilgileri;
        let ta = payForm.TeslimatBilgileri;
        delete payForm.UrunBilgileri;
        delete payForm.FaturaAdresiBilgileri;
        delete payForm.TeslimatBilgileri;

        payForm['Urunler'] = tempProducts;
        payForm['FaturaAdresi'] = fa;
        payForm['TeslimatAdresi'] = ta;

        return this.http.post(`https://pay.glopark.com/PayU/ApiPayment3DSecure`, payForm)
            .pipe(map(res => {
                if (res["status"] == 200) {
                    return JSON.parse(res["_body"])
                } else {
                    return null
                }
            }))
    }

    checkPaymentResult(transactionId) {
        return this.http.get(`https://pay.glopark.com/paytr/CheckOrderPayment?firmaGuid=${Constants.guid}&islemKodu=${transactionId}`)
            .pipe(map(res => {
                return res;
            }))
    }

    orderPaymentEnd(body) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/orderPaymentEnd/${Constants.b2cApiUrl}` , body , httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }

    listSavedCard(contactId, test?) {
        let url = `https://pay.glopark.com/PayU/CardTokenList?firmaGuid=${Constants.guid}&contactId=${contactId}`;
        if (test) {
            url = `${url}&test=true`
        }
        return this.http.get(url)
            .pipe(map(res => {
                if (res["status"] == 200) {
                    return JSON.parse(res["_body"])
                } else {
                    return null
                }
            }))
    }

    removeSavedCard(cardToken) {
        let url = `https://pay.glopark.com/PayU/CardTokenDelete?token=${cardToken}`;

        return this.http.get(url)
            .pipe(map(res => {
                if (res["status"] == 200) {
                    return JSON.parse(res["_body"])
                } else {
                    return null
                }
            }))
    }

    payuBinService(formValues): Observable<any> {
        formValues.firmaGuid = Constants.guid;
        return this.http.post(`https://pay.glopark.com/PayU/BinServiceV2`, formValues)
            .pipe(map(res => {
                if (res["status"] == 200) {
                    return JSON.parse(res["_body"])
                } else {
                    return null
                }
            }))
    }

    virtualBinService(card6digit) {
        return this.http.get(`https://pay.glopark.com/PayGlopark/GloparkBinServis?firmaGuid=${Constants.guid}&cardPrefix=${card6digit}`)
            .pipe(map(res => {
                return res;
            }))
    }

    installmentForVirtualPos() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/sanal-pos-ayarlar/list`, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }


    virtualPos3d(payForm) {
        payForm['Config']['FirmaGuid'] = Constants.guid;
        return this.http.post(`https://pay.glopark.com/PayGlopark`, payForm)
            .pipe(map(res => {
                return res;
            }))
    }

    getBankList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.get(`${Constants.b2cApiUrl}/banka-info`, httpOptions)
            .pipe(map(res => {
                return res;
            }))
    }


}