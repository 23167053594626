import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants } from '../constants';
import { Category } from '../interfaces/category';

@Injectable()
export class CollectionService {
    collections: any;
    collectionTree: any;
    mainCollections: Array<any>;
    collectionType = { story: '0', main: '1' };
    storyList: Array<any>;
    collectionChangeDetector = new BehaviorSubject(null);
    collectionListDetector = new BehaviorSubject(null);
    productListDetector = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private accountService: AccountService
    ) { }

    listCollections(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/collection/list/tree?companyId=0`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.collections = res['data'];
                    return this.collections;
                } else {
                    return [];
                }
            }))
    }

    list(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.b2cApiUrl}/collection/list?companyId=${this.accountService.getAccount['CompanyId']}`, httpOptions)
            .pipe(map(res => {
                if (res['status'] == 200) {
                    this.collections = JSON.parse(res['_body']);
                    return this.collections;
                } else {
                    return [];
                }
            }))
    }

    listCollectionTree(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
                'Content-Type': 'application/json'
            })
        }
        let companyId = this.accountService.getAccount && this.accountService.getAccount['CompanyId']  ? this.accountService.getAccount['CompanyId'] : 0;
        return this.http.get(`${Constants.b2cApiUrl}/collection/list/tree?companyId=${companyId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.collectionTree = res['data'];
                    this.collections = this.collectionTree.reduce((collections, c) => {
                        if (c.Children.length > 0) {
                            c.Item['Children'] = c.Children;
                            collections.push(c.Item);
                            collections.push(...c.Children.map(c => c.Item));
                            return collections
                        } else {
                            collections.push(c.Item);
                            return collections
                        }

                    }, []);

                    this.storyList = this.getStory.sort((a, b) => { return a.Sira - b.Sira });
                    this.mainCollections = this.getMainCollection.sort((a, b) => { return a.Sira - b.Sira });
                    return [...this.storyList , ...this.mainCollections];

                }
            }))
    }

    walkTree(categoriesType: 'shop' | 'blog', categoriesDef, parents: Category[] = []): [Category[], Category[]] {
        let list: Category[] = [];
        const tree: Category[] = categoriesDef.map(categoryDef => {
            
            const category: Category = {
                id: categoryDef.Id ? categoryDef.Id : categoryDef.Item.Id,
                type: categoriesType,
                name: categoryDef.KoleksiyonAdi ? categoryDef.KoleksiyonAdi : categoryDef.Item.KoleksiyonAdi,
                slug: categoryDef.HandleUrl ? categoryDef.HandleUrl : categoryDef.Item.HandleUrl,
                path: [...parents.map(x => x.slug), categoryDef.HandleUrl ? categoryDef.HandleUrl : categoryDef.Item.HandleUrl].join('/'),
                image: (categoryDef.ResimUrl ? categoryDef.ResimUrl : categoryDef.Item?.ResimUrl)|| null,
                items: (categoryDef.items ? categoryDef.items : categoryDef.Item?.items) || 0,
                customFields: {},
                parents: parents.slice(),
                children: [],
            };

            const [childrenTree, childrenList] = this.walkTree(categoriesType, (categoryDef.Children ? categoryDef.Children : categoryDef.Item?.Children) || [], [...parents, category]);

            category.children = childrenTree;
            list = [...list, category, ...childrenList];

            return category;
        });

        return [tree, list];
    }

    listTree(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ZGV2OmdsbzEyMzY1NCoq',
                'Content-Type': 'application/json'
            })
        }
        let companyId = this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : 0;
        return this.http.get(`${Constants.b2cApiUrl}/collection/list/tree?companyId=${companyId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    this.collectionTree = res['data'];
                    this.collections = this.collectionTree.reduce((collections, c) => {
                        if (c.Children.length > 0) {
                            c.Item['Children'] = c.Children;
                            collections.push(c.Item);
                            collections.push(...c.Children.map(c => c.Item));
                            return collections
                        } else {
                            collections.push(c.Item);
                            return collections
                        }

                    }, []);

                    this.storyList = this.getStory.sort((a, b) => { return a.Sira - b.Sira });
                    this.mainCollections = this.getMainCollection.sort((a, b) => { return a.Sira - b.Sira });
                    return res['data'];
                }
            }))
    }

    set setcollections(c) {
        this.collections = c;
    }

    get getCollection(): any {
        return this.collections;
    }

    get getStory(): any {
        if (!this.collectionTree) {
            return [];
        } else {
            return this.collections.filter(c => {
                return c.GosterimYeri == this.collectionType.story
            });
        }

    }

    get getMainCollection(): any {
        if (!this.collectionTree) {
            return [];
        } else {
            return this.collections.filter(c => {
                return c.GosterimYeri == this.collectionType.main
            });
        }

    }

    collectionWithChildren(id) {
        return this.collectionTree.find(c => { return c.Item.Id == id });
    }

    getShopCategory(slug: string): Observable<Category> {

        return this.listCollectionTree().pipe(map(x => {

            const [shopCategoriesTree, shopCategoriesList]: [Category[], Category[]] = this.walkTree('shop', x);

            const category = shopCategoriesList.find(x => x.slug === slug);

            if (!category) {
                return throwError(new HttpErrorResponse({ status: 404, statusText: 'Page Not Found' }));
            }

            return JSON.parse(JSON.stringify({
                ...category,
                parents: this.limitDepth(category.parents, 0),
                children: this.limitDepth(category.children, 0),
            }));

        }))
    }

    limitDepth(categories: Category[], depth: number): Category[] {
        return categories.map(category => {
            return {
                ...category,
                parents: null,
                children: depth !== 0 ? this.limitDepth(category.children, depth - 1) : null,
            };
        });
    }

}