<div class="widget-tags widget">
    <h4 class="widget__title">Tags Cloud</h4>
    <div class="tags tags--lg">
        <div class="tags__list">
            <a routerLink="./">Promotion</a>
            <a routerLink="./">Power Tool</a>
            <a routerLink="./">New Arrivals</a>
            <a routerLink="./">Screwdriver</a>
            <a routerLink="./">Wrench</a>
            <a routerLink="./">Mounts</a>
            <a routerLink="./">Electrodes</a>
            <a routerLink="./">Chainsaws</a>
            <a routerLink="./">Manometers</a>
            <a routerLink="./">Nails</a>
            <a routerLink="./">Air Guns</a>
            <a routerLink="./">Cutting Discs</a>
        </div>
    </div>
</div>
