import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  
  companyInfos = {
    name: 'Samsung Evi',
    contact: {
      address: 'Kurtuluş Mah. Kıbrıs Cad. No:54/A Efeler/AYDIN',
      phones: [
        {
          title: 'İletişim',
          no: '0 256 214 4242'
        },
        {
          title: '',
          no: '0 256 212 58 50'
        }
      ],
      fullName: '',      
      fax: '',
      email: 'admin@samsungevi.com'
    },
    social: [
      // {
      //   type: 'twitter',
      //   url: 'https://twitter.com/mobimbooffical',
      //   icon: 'fab fa-twitter'
      // },
      // {
      //   type: 'facebook',
      //   url: 'https://www.facebook.com/mobimbocom',
      //   icon: 'fab fa-facebook-f'
      // },
      // {
      //   type: 'youtube',
      //   url: 'https://www.youtube.com/channel/UCcydbDD7yuOsgANa43KcihA',
      //   icon: 'fab fa-youtube'
      // },
      {
        type: 'instagram',
        url: 'https://instagram.com/samsungmacrocom?utm_medium=copy_link',
        icon: 'fab fa-instagram'
      }
    ]
  }
  theme = {
    type: 'shop', // shop - gallery
    title: 'Samsung Evi',
    logo: {
      selected: 'text', // text - image
      text: 'Samsung Evi',
      headerLogo: '',
      footerLogo: ''
    },
    favicon: '',
    maintenance: {
      active: false,
      leftImage: 'test'
    },
    showNoStock: 1
  }

  constructor() { }
  // _theme-variables.scss dosyasında $accent-color == menü bg rengi burdan değişiyor ve button renkleri vs.
}
