<div class="site-footer__widget footer-contacts">
    <h5 class="footer-contacts__title">İletişim</h5>

    <div class="footer-contacts__text">
        {{settings.companyInfos.contact.fullName}}
    </div>

    <ul class="footer-contacts__contacts">
        <li><i class="footer-contacts__icon fas fa-globe-americas"></i> <a href="https://goo.gl/maps/VFrizRuEEzKcWYiv8" target="_blank">{{ settings.companyInfos.contact.address }}</a></li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> <a href="mailto:{{settings.companyInfos.contact.email}}">{{ settings.companyInfos.contact.email }}</a></li>
        <li *ngFor="let item of settings.companyInfos.contact.phones"><i class="footer-contacts__icon fas fa-mobile-alt"></i> <a href="tel:{{item['no']}}" >{{ item['no']}}</a></li>
        <!-- <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li> -->
    </ul>
</div>
