import { isNgTemplate } from '@angular/compiler';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BannerService } from 'src/app/shared/services/banners';
import { DirectionService } from '../../../shared/services/direction.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;

    options = {
        nav: true,
        dots: true,
        loop: true,
        responsive: {
            0: {items: 1}
        },
        navClass : ['owl-prev' , 'owl-next'],
        rtl: this.direction.isRTL(),
        navText: ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>']
    };

    slides = [
    ];

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private bannerService:BannerService,
        private router:Router
    ) {
        bannerService.list().subscribe(res =>{
            res.forEach(element => {
               this.slides.push({
                   title : element.label,
                   text : null,
                   image_classic : element.ResimUrl,
                   image_full:element.ResimUrl,
                   image_mobile:element.ResimUrl,
                   url:element.YonlendirmeURL,
                   type:element.YonlendirmeTipi
               }) 
            });

        })
     }

     bannerRoute(a,b){
         if(b == 0 && a){
             //koleksiyona yönlendirir
             this.router.navigateByUrl('/collection/' + a)
         }
         else if(b == 1 && a){
             //ürüne yönlendirir
             this.router.navigateByUrl('/product/' + a)
         }

     }
}
