export class Constants {
    public static get siteUrl(): string { return `https://mobimbo.com`; };
    public static get guid(): string { return "EBD14247-7680-4979-ADF3-E7BEFF23CC71"; };
    public static get apiId(): number { return 10180; };
    public static get platformId(): number { return 25; };
    public static get gloparkApiUrl(): string { return `https://tija-api.glopark.com/api/b2c/${this.guid}`; };
    public static get b2cApiUrl(): string { return `https://tija-api.glopark.com/api/b2c/firma/${this.guid}`; };
    public static get pureB2cApiUrl(): string { return `https://tija-api.glopark.com/api/b2c`; };
    public static get contactsApiUrl(): string { return `https://tija-api.glopark.com/api/b2c/firma/${this.guid}`; };
    public static get b2bApiUrl(): string { return `https://tija-api.glopark.com/api/b2b/firma/${this.guid}`; };
    public static get orderApiUrl(): string { return `https://tija-api.glopark.com/api-order-v2/api/firma/${this.guid}`; };
    public static get circleApiUrl(): string { return `https://tija-api.glopark.com/api-circle/api/firma/${this.guid}`; };
    public static get Authorization(): string {return `Basic ZGV2OmdsbzEyMzY1NCoq`};
    public static get successUrl(): string { return `https://pay.glopark.com`; };
    public static get errorUrl(): string { return `https://pay.glopark.com`; };

    public static get orderPrefix(): string { return `m`; };

    public static get payGloparkUrl(): string { return `https://pay.glopark.com`; };

    //local
    // public static get gloparkApiUrl(): string { return `http://localhost:56690/api/b2c/${this.guid}`; };
    // public static get b2cApiUrl(): string { return `http://localhost:56690/api/b2c/firma/${this.guid}`; };
    // public static get pureB2cApiUrl(): string { return `http://localhost:56690/api/b2c`; };
    // public static get payGloparkUrl(): string { return `http://localhost:52257/`; };
    // public static get payGloparkUrl(): string { return `http://localhost:52257/`; };
}