import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Constants } from '../constants';
import { isPlatformBrowser } from '@angular/common';
import { Guid } from 'guid-typescript';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private account: Account;
    public isLogged: boolean = false;
    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(PLATFORM_ID)
        private platformId: any)
         {
        
    }
    ngOnInit(){
        if (!this.isloggedIn) {
            let uuid = localStorage?.getItem('uuid')
            if(!uuid){
                let x;
                x = Guid.create()
                localStorage?.setItem('uuid', x);
            }

        }
    }

    uuidSet(){
        if (!this.isloggedIn) {
            let uuid = localStorage?.getItem('uuid')
            if(!uuid){
                let x;
                x = Guid.create()
                localStorage?.setItem('uuid', x);
            }

        }
    }

    loadAccount(accountId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${Constants.contactsApiUrl}/contacts/contact/${accountId}`, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    updateAccount(account): Observable<any> {
        account['FirmaGuid'] = Constants.guid;
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.contactsApiUrl}/contact-single/update`, account, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    get isloggedIn(): boolean {
        this.isLogged = JSON.parse(localStorage.getItem('isloggedIn')) as boolean || false;
        return this.isLogged
    }

    set login(value) {
        localStorage.setItem('isloggedIn', 'true')
        this.isLogged = true;
    }

    logout() {
        let uuid = localStorage.getItem('uuid');
        this.router.navigateByUrl('/giris')
        localStorage.clear();
        localStorage.setItem('uuid', uuid);
        this.isLogged = false;
        this.account = null;
    }

    get getAccount(): Account {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('deneme', 'deneme');
            this.account = JSON.parse(localStorage.getItem('Account')) as Account;
        }
        return this.account;
    }

    set setAccount(_p_account: Account) {
        this.account = _p_account;
        localStorage.setItem('Account', JSON.stringify(this.account));
    }
}