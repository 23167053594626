import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable()
export class FilterService {
    attributes:Array<any>;
    selected = {};
    filterBar = false;
    constructor(
        private http: HttpClient
    ){ }

    getAttributes(collectionId, filters = [], search?): Observable<any> {
        this.attributes = []
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        if(search) {
            filters.push({ 'name': 'SEARCH', values: [search] })
        }
        
        let requestUrl = `${Constants.b2cApiUrl}/attribute/list/${Constants.platformId}/${Constants.apiId}/${collectionId}`
        return this.http.post(requestUrl, filters, httpOptions)
        .pipe(map(res => {
            if (res['status']) {
                this.attributes = res['data'] ? res['data'] :  [];
                if (Object.entries(this.selected).length === 0 && this.selected.constructor === Object) {
                    this.organizeSelected();
                }
            }
        }))
    }

    getFilters(collectionId, filters = [], search?): Observable<any> {
        this.attributes = []
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        if(search) {
            filters.push({ 'name': 'SEARCH', values: [search] })
        }
        
        let requestUrl = `${Constants.b2cApiUrl}/attribute/list/${Constants.platformId}/${Constants.apiId}/${collectionId}`
        return this.http.post(requestUrl, filters ? filters : [], httpOptions).pipe(map(res => {
            if (res['status']) {
                return this.makeFilters(res['data']);
            }
        }))
    }

    makeFilters(filtersData) {

        let filters = [];

        filtersData.forEach(element => {

            if (element.Name && element.Name !== 'KATEGORİ' && element.Values.length < 10) {

                if(element.Name === 'FİYAT') {

                    let filter: {name: string, slug: string, type: string, value: number[], min: number, max: number} = {
                        name: '', 
                        slug: '', 
                        type: '', 
                        value: [],
                        min: 0,
                        max: 0
                     };
                    let filterName: string = element.Name;
                    filter.name = filterName;
                    filter.slug = filterName.toLocaleLowerCase();
                    filter.type = 'range';
                    filter.value = [Math.floor(element.Values[0].Value.replace(/,/g, '.')), Math.round(element.Values[1].Value.replace(/,/g, '.'))];
                    filter.min = Math.floor(element.Values[0].Value.replace(/,/g, '.'));
                    filter.max = Math.round(element.Values[1].Value.replace(/,/g, '.'));
                    filters.push(filter);


                } else {

                    let filter: {name: string, slug: string, type: string, value: [], items: any[]} = {name: '', slug: '', type: '', value: [], items: []};
                    let filterName: string = element.Name;
                    filter.name = filterName;
                    filter.slug = filterName.toLocaleLowerCase();
                    filter.type = 'check';
                    filter.value = [];
                    filter.items = [];
                    element.Values.forEach(item => {

                        if(item.Value) {

                            let valueName: string = item.Value;
                            filter.items.push({slug: valueName.toLocaleLowerCase(), name: valueName, count: item.Count})

                        }
                        
                    });
                    filters.push(filter);

                }

            }
            
        });

        return filters;

    }

    getAttributesValue(name) {
        let attributes = this.attributes.find(a => { return a.Name == name });

        return attributes.Values.map(v => {
            return { Name: v.Value, Count: v.Count };
        })
    }

    organizeSelected() {
        for (const a of this.attributes) {
            this.selected[a.Name] = {};
        }
    }

    createFilterQuery() {
        let filterQuery = [];
        for (const attr in this.selected) {
            let filter = { name: attr, values: []};
            if (this.selected.hasOwnProperty(attr)) {
                for (const key in this.selected[attr]) {
                    if (this.selected[attr].hasOwnProperty(key)) {
                        if (attr == 'FİYAT') {
                            let val = `${this.selected[attr][key].lower};${this.selected[attr][key].upper}` ;
                            filter.values.push(val)
                        }else {
                            if (this.selected[attr][key]) {
                                filter.values.push(key)
                            }
                        }
                    }
                }
                filterQuery.push(filter);
            }
        }

        return filterQuery;
    }

}