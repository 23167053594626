import { Component, Input } from '@angular/core';
import { Link } from '../../../../shared/interfaces/link';

@Component({
    selector: 'app-footer-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss']
})
export class LinksComponent {
    @Input() header: string;
    @Input() links: Link[] = [];

    constructor() { }

    convertString(phrase) {
  
        var newUrl = phrase.toLowerCase();
        
        newUrl = newUrl.replace(/ö/g, 'o');
        newUrl = newUrl.replace(/ç/g, 'c');
        newUrl = newUrl.replace(/ş/g, 's');
        newUrl = newUrl.replace(/ğ/g, 'g');
        newUrl = newUrl.replace(/ü/g, 'u');  
        newUrl = newUrl.replace(/ı/g, 'i');  
    
        newUrl = newUrl.replace(/[^a-z0-9\s-]/g, "");
        newUrl
        newUrl = newUrl.replace(/[\s-]+/g, " ");
    
        newUrl = newUrl.replace(/^\s+|\s+$/g,"");
    
    
        newUrl = newUrl.replace(/\s/g, "-");  
    
        return newUrl;
        
      }
}
