import { Component, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { Router } from '@angular/router';


@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    account;
    mail;
    pass;
    constructor(
        private accountService:AccountService,
        private loginService: LoginService,
        private toastr: ToastrService,
        private socialAuthService: SocialAuthService,
        private router: Router,


    ) { 
        this.account = accountService.getAccount
    }

    logout(){
        localStorage.removeItem('Account')
        this.account = null
    }

    signin(a, b) {
        this.loginService.login(a, b).subscribe(res => {
            if (res.status == true) {
                this.toastr.success(res.description)
                this.accountService.setAccount = res.contact as Account;
                this.accountService.login = true;
                location.reload()
            }
            else {
                this.toastr.error(res.description)
            }
        })
    }


    loginWithGoogle(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((response) => {
            this.loginService.sendVerificationCode('1', response.email).subscribe(x => {
                if (x.Name == 'SUCCESS') {
                    this.loginService.verifyCode('1', response.email, '', undefined, false, false, null, true).subscribe(res => {
                        if (res.status == true) {
                            this.toastr.success('Başarılı ile giriş yapıldı.')
                            this.accountService.setAccount = res.contact as Account;
                            this.accountService.login = true;
                            let y = localStorage.getItem('checkoutlogin')
                            if (y == 'true') {
                                localStorage.removeItem('checkoutlogin')
                                this.router.navigate(['/shop/cart/checkout'])
                            }
                            else {
                                location.reload()
                            }
                        }
                        else {
                            this.toastr.error(res.description)
                        }
                    })
                }
            })
        });
    }

    logOut(): void {
        this.socialAuthService.signOut();
    }
}
