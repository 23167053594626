<div class="footer-newsletter">
    <h5 class="footer-newsletter__title">Sosyal Medya</h5>
    <!-- <div class="footer-newsletter__text">
        Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis felis at lacinia.
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address">Email Address</label>
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" placeholder="Email Address...">

        <button class="footer-newsletter__form-button btn btn-primary">Subscribe</button>
    </form>

    <div class="footer-newsletter__text footer-newsletter__text--social">
        Follow us on social networks
    </div> -->

    <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links>
</div>
