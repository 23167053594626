import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'src/app/shared/services/app.service';
import { CustomerService } from 'src/app/shared/services/customer-service';
import { theme } from '../../../data/theme';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    theme = theme;
    services = [];
    settings = {};

    whatsapp

    constructor(
        public customerService: CustomerService,
        private sanitizer: DomSanitizer,
        private appService: AppService

    ) {
        this.customerService.list().subscribe(res => {
            let x = res.data
            x.forEach(element => {
                this.services.push({ label: element.Baslik, url: element.Id })
            });
        });

        appService.settings().subscribe(res => {
            console.log(res)
            this.whatsapp = 'https://api.whatsapp.com/send?phone=' + res.whatsapptel;
        })


        this.settings = JSON.parse(localStorage.getItem('settings')) || {};
    }



    sanitize(url: string) {
        window.open(url, '_blank')
    }
}


