<div class="mt-4 product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <app-product-gallery [productLayout]="layout" [images]="product.images"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist"
                        appClick (click)="addToWishlist()"
                        [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Compare"
                        appClick (click)="addToCompare()"
                        [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>
            <h1 class="product__name">{{ product.name }}</h1>
            <!-- <div class="product__rating">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="" appClick>{{ product.reviews }} Reviews</a><span>/</span><a href="" appClick>Write A Review</a>
                </div>
            </div> -->
            <!-- <div class="product__description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare elementum, libero nibh
                lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius nisi.
            </div> -->
            <ul class="product__features">
                <li>Speed: 750 RPM</li>
                <li>Power Source: Cordless-Electric</li>
                <li>Battery Cell Type: Lithium</li>
                <li>Voltage: 20 Volts</li>
                <li>Battery Capacity: 2 Ah</li>
            </ul>
            <ul class="product__meta">
                <li class="product__meta-availability">Durum: <span class="text-success">Stokta</span></li>
                <li>Marka: <a href="" appClick>{{product.brand}}</a></li>
                <li>SKU: {{product.sku}}</li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                Durum: <span class="text-success">Stokta</span>
            </div>

            <div class="product__prices">
                <ng-container *ngIf="product.compareAtPrice">
                    <span class="product__new-price">{{ product.price|currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">
                    {{ product.price|currencyFormat }}
                </ng-container>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Color</label>
                    <div class="input-radio-color">
                        <div class="input-radio-color__list">
                            <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="White">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="Yellow">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="Red">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="Blue">
                                <input type="radio" name="color" disabled>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <ng-container *ngFor="let variation of attributes;index as i">
                    <div class="form-group product__option"  *ngIf="variation.isOpen">
                        <label class="product__option-label">{{variation.name}}</label>
                        <div class="input-radio-label">
                            <div class="input-radio-label__list">
                                <ng-container  *ngFor="let item of variation.values">
                                    <label *ngIf="item.isOpen">
                                        <input type="radio" *ngIf="item.isSelected" [name]="i">
                                        <span (click)="changeVariantSize(variation, item)">{{item.value}}</span>
                                    </label>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="form-group product__option">
                    <label class="product__option-label">Stok</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number *ngIf="product['quantity'] > 0" aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [max]="product['quantity']" [formControl]="quantity"></app-input-number>
                            <p *ngIf="product['quantity'] == 0 || product['quantity'] < 0" class="text-danger font-weight-bold mt-2">STOKTA YOK</p>
                        </div>
                        <div *ngIf="product['quantity'] > 0" class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg"
                                    [ngClass]="{'btn-loading': addingToCart}"
                                    appClick (click)="addToCart()">Sepete ekle</button>
                        </div>
                        <!-- <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Wishlist"
                                    [ngClass]="{'btn-loading': addingToWishlist}"
                                    appClick (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div> -->
                        <!-- <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Compare"
                                    [ngClass]="{'btn-loading': addingToCompare}"
                                    appClick (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div> -->
                    </div>
                </div>
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <div class="product__footer">
            <div class="product__tags tags" hidden>
                <div class="tags__list">
                    <a href="" *ngFor="let tag of product['tags']" appClick>{{tag}}</a>
                </div>
            </div>

            <app-share-buttons
                class="product__share-buttons"
                
                [pageUrl]="root.product(product)|absoluteUrl:'angular'"
                [pageTitle]="product.name"
                [pageImage]="product.images[0]|absoluteUrl:'html'"
            ></app-share-buttons>
        </div>
    </div>
</div>
