import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { CollectionService } from 'src/app/shared/services/collection.service';
import { link } from 'fs';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();

    isOpen = false;
    links: MobileMenuItem[] = mobileMenu;

    constructor(
        public mobilemenu: MobileMenuService,
        private collectionService: CollectionService
    ) { }

    ngOnInit(): void {
        this.collectionService.listCollectionTree().subscribe(res => {

            let navLink = [];

            res.forEach((element, i) => {

                if (true) {
                    if (element.GosterimYeri == 0) {

                    let navItem = { label: element.KoleksiyonAdi, url: '/collection/' + element.HandleUrl, type: 'link', children: [] };
                    
                    element.Children?.forEach(item => {

                        let navItemMenuItem = { label: item.Item.KoleksiyonAdi, url: '/collection/' + item.Item.HandleUrl, type: 'link' };

                        navItem.children.push(navItemMenuItem);

                    });

                    navLink.push(navItem);
                }
                }

            });

            this.links = navLink;

        })
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }

        // if (event.data && event.data.language) {
        // }
    }
}
