import { Component, HostBinding, Input } from '@angular/core';
import { theme } from '../../../../data/theme';
import { SettingsService } from '../../services/settings.service';

export interface SocialLinksItem {
    type: string;
    url: string;
    icon: string;
}

export type SocialLinksShape = 'circle' | 'rounded';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent {
    theme = theme;

    items:any

    @Input() shape: SocialLinksShape;

    @HostBinding('class.social-links') classSocialLinks = true;

    @HostBinding('class.social-links--shape--circle') get classSocialLinksShapeCircle(): boolean { return this.shape === 'circle'; }

    @HostBinding('class.social-links--shape--rounded') get classSocialLinksShapeRounded(): boolean { return this.shape === 'rounded'; }

    constructor(
        private settings:SettingsService
    ) {
        this.items = settings.companyInfos.social
        console.log('items',this.items)
     }
}
