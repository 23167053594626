import { Component, Input } from '@angular/core';
import { CollectionService } from 'src/app/shared/services/collection.service';
import { Category } from '../../../shared/interfaces/category';
import { RootService } from '../../../shared/services/root.service';

@Component({
    selector: 'app-block-categories',
    templateUrl: './block-categories.component.html',
    styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent {
    @Input() header = '';
    @Input() layout: 'classic' | 'compact' = 'classic';
    @Input() categories: Category[] = [];

    constructor(
        public root: RootService,
        private collectionService: CollectionService
    ) {
        collectionService.listTree().subscribe(res => {
            this.categories = []
            res.forEach(element => {
                if (element.Item.GosterimYeri == '1') {
                    this.categories.push(
                        {
                            'id': element.Item.Id,
                            'type': 'shop',
                            'name': element.Item.KoleksiyonAdi,
                            'slug': element.Item.HandleUrl,
                            'path': element.Item.HandleUrl,
                            'image': element.Item.ResimUrl,
                            'items': null,
                            'customFields': null,
                            'parents': null,
                            'children': null,
                        }
                    )
                }
            });
        })
    }
}
