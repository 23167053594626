import { Component } from '@angular/core';

@Component({
    selector: 'app-block-product-columns',
    templateUrl: './block-product-columns.component.html',
    styleUrls: ['./block-product-columns.component.scss']
})
export class BlockProductColumnsComponent {
    constructor() { }
}
