import { Injectable, Inject } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants } from '../constants';
import { SettingsService } from './settings.service';

import { Product } from '../interfaces/product';
import { ProductsList } from '../interfaces/list';
import { element } from 'protractor';
import { CollectionService } from './collection.service';
import { CategoryFilterItem, Filter } from '../interfaces/filter';
import { Category } from '../interfaces/category';
import { FilterService } from './filter.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    products = [] as Product[];
    productsList = {} as ProductsList;
    product = {} as Product;
    pager: any;
    modalImages;
    public filterBar: boolean = false;
    constructor(
        private http: HttpClient,
        private accountService: AccountService,
        private settingsService: SettingsService,
        private collectionService: CollectionService,
        private filterService: FilterService
    ) { }

    list(searchCriteria, skip, take, hUrl, order?): Observable<Product[]> {
        let requestPayload = {
            ApiId: Constants.apiId,
            FirmaGuid: Constants.guid,
            PlatformId: Constants.platformId,
            //KoleksiyonId: searchCriteria.collectionId,
            Handleurl: hUrl,
            Search: searchCriteria.search,
            Filter: { attributes: searchCriteria.filters },
            Skip: skip,
            Take: take,
            Order: order,
            QuantityStatus: this.settingsService.theme.showNoStock,
            CompanyId: this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : null
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.b2cApiUrl}/mobil/product/list`, requestPayload, httpOptions)

            .pipe(map(res => {
                if (res['result']['status']) {

                    res['data'].forEach(element => {

                        let item = {} as Product;

                        item.name = element.title;
                        item.id = element.id;
                        item.brand = element.marka;
                        item.attributes = [];
                        item.images = [element.image[0].url];
                        item.price = element.price;
                        item.slug = element.handleUrl;
                        item['Quantity'] = element.variationCount,
                        item['stockItem'] = element.stockItem,
                        item.compareAtPrice = element.displayPrice != element.price ? element.displayPrice : null


                            this.products.push(item);

                    });

                    this.pager = res['pagingData'];
                    return this.products;
                }
            }))
    }

    prepareAttributes(filterValues) {

        let attributes = [];

        for (let filterName in filterValues) {

            if (filterName == 'fiyat') {

                let attribute = { name: filterName, values: [filterValues[filterName].replace('-', ';')] }
                attributes.push(attribute);

            } else {

                let attribute = { name: filterName, values: filterValues[filterName].split(',') }
                attributes.push(attribute);

            }


        }

        return attributes;

    }

    parseFilterValue(filter: Filter, value: string): any {
        switch (filter.type) {
            case 'range':
                return value.split('-').map(x => parseFloat(x));
            case 'check':
            case 'color':
                return value.trim() === '' ? [] : value.split(',').map(x => x.trim());
        }

        return value;
    }

    getProductsList(hUrl, options, order?, search = ""): Observable<ProductsList> {
        console.log('sadasdasd', hUrl, options, order, search)
        let requestPayload = {
            ApiId: Constants.apiId,
            FirmaGuid: Constants.guid,
            PlatformId: Constants.platformId,
            //KoleksiyonId: searchCriteria.collectionId,
            Handleurl: hUrl,
            Search: search,
            Filter: {},
            Skip: (options.page - 1) * options.limit,
            Take: options.limit,
            Order: order,
            QuantityStatus: this.settingsService.theme.showNoStock,
            CompanyId: this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : null
        }

        const filterValues = options.filterValues || {};

        const filters$ = this.collectionService.listCollectionTree().pipe(
            mergeMap(x => {
                const filters: Filter[] = [];

                const [shopCategoriesTree, shopCategoriesList]: [Category[], Category[]] = this.collectionService.walkTree('shop', x);

                if (hUrl === null) {
                    filters.push({
                        type: 'categories',
                        slug: 'categories',
                        name: 'Kategoriler',
                        root: true,
                        items: [
                            ...shopCategoriesTree.map(x => this.makeCategoryFilterItem('child', x)),
                        ],
                    });
                    console.log(filters, 1)
                    return of(filters);
                } else {
                    const category = shopCategoriesList.find(x => x.slug === hUrl);
                    console.log(33, shopCategoriesList, hUrl)

                    if (!category) {
                        return throwError(new HttpErrorResponse({ status: 404, statusText: 'Page Not Found' }));
                    }
                    console.log(category)
                    filters.push({
                        type: 'categories',
                        slug: 'categories',
                        name: 'Kategoriler',
                        root: false,
                        items: [
                            ...category.parents.map(x => this.makeCategoryFilterItem('parent', x)),
                            this.makeCategoryFilterItem('current', category),
                            ...category.children.map(x => this.makeCategoryFilterItem('child', x)),
                        ],
                    });

                    return this.filterService.getFilters(category.id, null, null).pipe(
                        map(x => {

                            x.forEach(element => {
                                filters.push(element);
                            });
                            console.log(filters, 2)
                            return filters;
                        })
                    )
                }
            })
        );

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        };

        const apiResponse$ = this.http.post(`${Constants.b2cApiUrl}/mobil/product/list`, requestPayload, httpOptions);
        console.log('3')
        return combineLatest([
            filters$,
            apiResponse$
        ]).pipe(
            map(([filters, res]) => {
                console.log('1')
                if (res['result']['status']) {
                    let items = [];

                    res['data'].forEach(element => {
                        console.log('2', res['data'])
                        let item = {} as Product;

                        item.name = element.title;
                        item.id = element.id;
                        item.brand = element.marka;
                        item.attributes = [];
                        item.images = element.image.length > 0 ? [element?.image[0]?.url] : ['https://www.bcm-institute.org/wp-content/uploads/2020/11/No-Image-Icon.png'];
                        item.price = element.price;
                        item.slug = element.handleUrl
                        item.compareAtPrice = element.displayPrice != element.price ? element.displayPrice : null

                        items.push(item);

                    });

                    let page = res['pagingData'].currentPage;
                    let limit = res['pagingData'].take;
                    let total = res['pagingData'].totalCount;
                    let pages = res['pagingData'].pageCount;
                    let from = res['pagingData'].skip;
                    let to = res['pagingData'].skip + res['pagingData'].take;

                    filters.forEach(filter => {
                        if (filter.slug in filterValues && 'value' in filter) {
                            filter.value = this.parseFilterValue(filter, filterValues[filter.slug]);
                        }
                    });


                    this.productsList = {
                        items,
                        page,
                        limit,
                        total,
                        pages,
                        from,
                        to,
                        sort: null,
                        filters,
                        filterValues
                    };

                    this.pager = res['pagingData'];
                    console.log('products', this.productsList)
                    return this.productsList;
                }
            })
        );
    }

    makeCategoryFilterItem(type: 'parent' | 'current' | 'child', category: Category): CategoryFilterItem {
        return {
            slug: category.slug,
            name: category.name,
            type,
            category: { ...category, children: null, parents: null },
            count: category.items,
        };
    }

    getProductDetail(handleUrl = "", id?): Observable<Product> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.get(`${Constants.b2cApiUrl}/mobil/product/detail/${Constants.platformId}/${Constants.apiId}/${id}?handleUrl=${handleUrl}`, httpOptions)
            .pipe(map((res: any) => {
                console.log('asdasads', res)
                if (res['status']) {

                    let items = [];
                    let x = [res['data']]

                    console.log("test", x);

                    x.forEach(element => {
                        let item = {} as Product
                        item.name = element.title
                        item.id = element.id
                        item.brand = element.marka
                        item.attributes = []
                        item.images = [];
                        element.image.forEach(x => {
                            item.images.push(x.url);
                        });
                        item.price = element.salePrice
                        item.compareAtPrice = element.displayPrice
                        item.slug = element.handleUrl
                        item['description'] = element.description
                        item.sku = element.productSellerCode
                        item['tags'] = element.etiketler != null ? element.etiketler.split(' , ') : ""
                        item['variations'] = element?.stockItem
                        item['quantity'] = element.quantity
                        items.push(item)

                    });
                    return items

                    this.modalImages = res['data']['image'];
                    return res['data'];
                }
            }))
    }

    getProductSizeTable(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.get(`${Constants.b2cApiUrl}/urun-beden-tablosu/${id}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                }
            }))
    }

    getProductAttributes(stockItems) {
        stockItems.sort((a: any, b: any) => {
            if (a.order < b.order) {
                return -1;
            } else if (a.order > b.order) {
                return 1;
            } else {
                return 0;
            }
        });
        let attributes = [];
        for (let stock of stockItems) {
            for (let [index, attribute] of stock.stockItemAttribute.entries()) {
                let propertie = { name: attribute.name, isOpen: index == 0 ? true : false, values: [{ value: attribute.value, amount: stock.quantity, isOpen: index == 0 ? true : false, isSelected: false }] };
                let temp = attributes.find(a => { return a.name == attribute.name });
                if (temp) {
                    let duplicateValue = temp.values.find(v => { return v.value == attribute.value })
                    if (!duplicateValue) {

                        temp.values.push({ value: attribute.value, amount: stock.quantity, isOpen: index == 0 ? true : false, isSelected: false });
                    }
                } else {
                    attributes.push(propertie);
                }
            }
        }

        return attributes;
    }

    getFilteredStockItems(stockItems, attName, attValue) {
        let filteredStockItems = [];
        for (const si of stockItems) {
            for (const sat of si.stockItemAttribute) {
                if (sat.name == attName && sat.value == attValue) {
                    filteredStockItems.push(si);
                }
            }
        }

        return filteredStockItems;
    }

    findStockItem(stockItem, attributes) {
        let item_guid = '';

        if (Object.entries(attributes).length === 0 && attributes.constructor === Object) {
            item_guid = stockItem[0].ItemGuid;
        } else {
            for (const stock of stockItem) {
                let valid = 0;
                for (const stockAttribute of stock.stockItemAttribute) {
                    for (const key in attributes) {
                        if (attributes.hasOwnProperty(key)) {
                            if (stockAttribute.name == key && stockAttribute.value == attributes[key]) {
                                valid++;
                            }
                        }
                    }
                    if (valid == Object.keys(attributes).length) {
                        item_guid = stock.ItemGuid;
                    }
                }
            }
        }

        return item_guid;
    }

    getSmartSearchParameters(searchKey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.b2cApiUrl}/mobil/${Constants.platformId}/${Constants.apiId}/search?skip=0&take=10&companyId=${this.accountService.getAccount['CompanyId']}`, { search: searchKey }, httpOptions)
            .pipe(map(res => {
                if (res['result']['status']) {
                    return res['data'];
                }
            }))
    }
}
