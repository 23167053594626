import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Constants } from '../constants'
@Injectable()
export class LoginService {
    constructor(
        private http: HttpClient
    ) { }

    sendVerificationCode(registerType: string, identityValue: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/member-auth-code`
            , {
                IletisimTipi: registerType,
                Iletisim: identityValue
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    verifyCode(registerType, identityValue, verificationCode, code, secret = false, condition = false, token?, ThirdPartyLogin?: boolean): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/member-auth`
            , {
                IletisimTipi: registerType,
                Iletisim: identityValue,
                DogrulamaKodu: verificationCode == '' ? 0 : verificationCode,
                FirmaKodu: code,
                Token: token,
                Gizlilik: secret,
                KullanimKosul: condition,
                ThirdPartyLogin: ThirdPartyLogin ?? false,
                ThirdPartyLoginPass: "GloparkB2CThirdPartyLogin??*%%&&"
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    login(email: string, password: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/signin`
            , {
                email: email,
                password: password
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }


    signup(email: string, fullName: string, identityNo: number, telefon: number, Gizlilik: any, KullanimKosul: any, password: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/signup`
            , {
                email: email,
                fullName: fullName,
                identityNo: identityNo,
                telefon: telefon,
                Gizlilik: Gizlilik,
                KullanimKosul: KullanimKosul,
                password: password,
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }

    passReset(email: string,url:any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/forgot-password`
            , {
                email: email,
                resetPassword:true,
                url:url
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }


    passChange(resetPass: boolean, tid: string, eid: string, contactId: number, oldPass: string, newPass: string, renewPass: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(`${Constants.gloparkApiUrl}/change-password`
            , {
                resetPassword: resetPass,
                tid: tid,
                eid: eid,
                contactId: contactId,
                oldPassword: oldPass,
                newPassword: newPass,
                renewPassword: renewPass
            }, httpOptions)
            .pipe(map(res => {
                return res
            }))
    }


}