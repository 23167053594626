<div class="widget-comments widget">
    <h4 class="widget__title">Latest Comments</h4>
    <ul class="widget-comments__list">
        <li *ngFor="let comment of comments" class="widget-comments__item">
            <div class="widget-comments__author"><a routerLink="./">{{ comment.author }}</a></div>
            <div class="widget-comments__content">{{ comment.text }}</div>
            <div class="widget-comments__meta">
                <div class="widget-comments__date">{{ comment.date }}</div>
                <div class="widget-comments__name">On <a routerLink="./" title="{{ comment.postTitle }}">{{ comment.postTitle }}</a></div>
            </div>
        </li>
    </ul>
</div>
