<!-- .block-slideshow -->
<div class="block-slideshow block"
    [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="">
        <div class="row p-0 m-0">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <div class="item-class owl-items cp">
                                <img class="h-100" (click)="bannerRoute(slide.url,slide.type)"
                                    [src]="withDepartments ? slide.image_classic : slide.image_full">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-slideshow / end -->