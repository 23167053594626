<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-4 text-center">
                    <app-footer-links header="Müşteri Hizmetleri" [links]="services"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="site-footer__widget footer-links">
                        <h5 class="footer-links__title">Hesabım</h5>
                        <ul class="footer-links__list">
                            <li class="footer-links__item"><a [routerLink]="'account/profile'" class="footer-links__link">Bilgilerim</a></li>
                        </ul>
                    </div>
                    
                </div>
                <div class="col-12 col-md-12 col-lg-2">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                    <!-- <div class="row mt-3">
                        <a class="w-50 cp" href="https://apps.apple.com/us/app/mobimbo/id1509691526">
                            <img class="w-100" src="assets/images/appstore.png"  alt="">
                        </a>
                        <a class="w-50 cp" href="https://play.google.com/store/apps/details?id=com.glopark.mobimbo&hl=tr">
                            <img class="w-100" src="assets/images/googleplay.png"  alt="">
                        </a>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                © Copyright 2021 <a href="https://www.glopark.com/tija">Tija</a> | Tüm Hakları Saklıdır
            </div>
            <div class="site-footer__payments">
                <img class="h30px" src="assets/images/troy-logo.png" alt="">
                <img class="h30px" src="assets/images/visa-logo.png" alt="">
                <img class="h30px" src="assets/images/master-logo.png" alt="">
                <img class="h30px" src="assets/images/trushsafe-logo.jpg" alt="">

            </div>
        </div>
    </div>
    <app-totop></app-totop>
</div>
<a href="{{whatsapp}}" target="_blank" class="whatsapp-btn">
    <i aria-hidden="true" class="fab fa-whatsapp"></i>
</a>